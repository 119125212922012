// @flow
import React from 'react'

import DefaultLayout from '../components/DefaultLayout'
import IntroStrip from '../blocks/IntroStrip'
import {Col, Row} from '../ui/layout'
import {FullButton} from '../ui/buttons'

const Help = () => (
  <DefaultLayout title="Superdays Help" description="We are here to help">
    <IntroStrip bottomWave="curve" headline="Superdays Help">
      <Col spacing={3} paddingVertical={3}>
        <div>
          Do you have a problem? Feedback? Anything we can help you with? <br />
          Email us or use the chat widget in the bottom right corner.
        </div>
        <Row justify="center">
          <FullButton
            label="Email us!"
            target="_blank"
            to="mailto:hello@superdays.fun?subject=Heeeeeeeeelp"
          />
        </Row>
      </Col>
    </IntroStrip>
  </DefaultLayout>
)

export default Help
